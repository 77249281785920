<template>
  <div class="animated">
    <CustomModal
      :modalTitle="$t('delete')"
      :button1Title="$t('delete')"
      button1Class="btn btn-danger"
      :show="removeModal"
      @hide="removeModal = false"
      @onButton1Click="remove()"
    >
      {{ $t("deleteConfirm") }}
    </CustomModal>
    <b-card no-body>
      <b-card-header>
        <i class="icon-menu mr-1"></i>
        {{ $t("authors") }}
        <div class="card-header-actions">
          <BrandButton
            :text="$t('newAuthor')"
            @click="add()"
            v-if="$auth.hasAuth($options, 'add')"
          ></BrandButton>
        </div>
      </b-card-header>
      <b-card-body>
        <v-client-table
          v-if="$auth.hasAuth($options, 'list')"
          :columns="columns"
          :data="list"
          :options="options"
          id="dataTable"
          class="dataGrid gridDetail"
        >
          <template slot="child_row" slot-scope="props">
            <div v-html="props.row.htmlBiography" class="text-left p-3"></div>
          </template>
          <template slot="profile" slot-scope="props">
            <div
              v-if="props.row.photoFile && props.row.photoFile.fileName && props.row.photoFile.fileName.length"
            >
              <b-img
                :src="props.row.photoFile.fileName"
                fluid
                height="40"
                width="40"
                thumbnail
                :id="getPopoverId(props.row.id)"
              />
              <b-popover
                :target="getPopoverId(props.row.id)"
                triggers="hover"
                placement="right"
              >
                <template #title>{{ $t("image") }}</template>
                <b-img
                  :src="props.row.photoFile.fileName"
                  thumbnail
                  fluid
                  height="400"
                  width="400"
                />
              </b-popover>
            </div>
            <div v-if="!(props.row.photoFile && props.row.photoFile.fileName && props.row.photoFile.fileName.length)">
              <b-img
                src="/img/noPic.png"
                fluid
                height="40"
                width="40"
                thumbnail
              />             
            </div>
          </template>
          <template slot="buttons" slot-scope="props">
            <div class="nowrap">
              <b-button
                variant="primary icon"
                v-b-tooltip.hover
                :title="$t('edit')"
                class="mr-1 btn-brand"
                v-if="$auth.hasAuth($options, 'edit')"
              >
                <i class="fa fa-edit" @click="edit(props.row.id)"></i>
              </b-button>
              <b-button
                variant="danger icon"
                v-b-tooltip.hover
                :title="$t('delete')"
                class="mr-1 btn-brand"
                v-if="$auth.hasAuth($options, 'remove')"
              >
                <i
                  class="fa fa-trash"
                  @click="showRemoveModal(props.row.id)"
                ></i>
              </b-button>
            </div>
          </template>
        </v-client-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import gridOptions from "../../shared/grid-options";
import moment from "moment";
import http from "../../shared/http";
import { ClientTable } from "vue-tables-2";
import utility from "../../shared/utils/screen-helper";
import CustomModal from "../../widgets/form/CustomModal";
import BrandButton from "../../widgets/form/CustomBrandButton";
import toast from "../../shared/toast-helper";

export default {
  name: "AuthorList",
  components: {
    ClientTable,
    CustomModal,
    BrandButton,
  },
  created() {
    this.getList();
  },
  methods: {
    getPopoverId(id) {
      return "popover" + id;
    },
    getList() {
      let path = "/Authors/getlist";
      http.get(path).then((response) => {
        if (response) this.list = response;
        utility.scrollToTop();
      });
    },
    add() {
      this.$router.push({ path: "author-add" });
    },
    edit(id) {
      this.$router.push({ path: `author-add/${id}` });
    },
    showRemoveModal(id) {
      this.removeModal = true;
      this.selectedId = id;
    },
    remove() {
      http
        .post(`Authors/delete?AuthorId=${this.selectedId}`)
        .then((response) => {
          if (response.success) {
            this.getList();
            this.removeModal = false;
            this.selectedId = 0;
            toast.success(response.message);
          }
        });
    },
  },
  data() {
    return {
      list: [],
      removeModal: false,
      selectedId: 0,
      columns: [
        "profile",
        "nameSurename",
        "url",
        "email",
        "seoKeywords",
        "buttons",
      ],
      options: {
        ...gridOptions.getAll(),
        debounce: 100,
        orderBy: {
          column: "id",
          ascending: false,
        },
        perPageValues: [10, 25, 50, 100],
        headings: {
          profile: this.$t("image"),
          nameSurename: this.$t("nameSurename"),
          url: this.$t("url"),
          email: this.$t("email"),
          seoKeywords: this.$t("seoKeywords"),
          buttons: this.$t("operations"),
        },
      },
    };
  },
};
</script>